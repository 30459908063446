
import { defineComponent } from "vue";
import adminArticle from "@/components/admin-article/admin-article.vue"

export default defineComponent({
  components: {
    adminArticle
  },
  setup() {
    return {}
  }
})
